import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../src/common/components/Layout'
import styles from '../../../src/common/pages/politicaPrivacidad.module.scss'
import CondicionesContents from '../static/condiciones'
import { SeoData } from '../../../src/common/types/Seo'
import { useSelectedMenu } from '../helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <CondicionesContents styles={styles} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CondicionesQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
