import React from 'react'

interface Props {
  styles: { [k: string]: string }
}

const CondicionesPt = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Condições gerais de contratação</h1>
    <p>
      Este acordo regula as condições gerais de compra dos diversos produtos e
      serviços oferecidos neste site pela COVIPNEUS LDA, 500228264 e endereço em
      Zona Industrial do Fundão Lote 4, Fundão, 6230 483.
    </p>
    <p>
      O CLIENTE no momento em que finaliza o processo de cadastro, e compra
      qualquer um de nossos produtos, aceita e se submete expressamente às
      cláusulas abaixo especificadas, bem como às condições de acesso e uso do
      nosso portal e sua política de privacidade, acessível através do link
      «Noticias legais».
    </p>
    <p>
      O CLIENTE reconhece ter capacidade jurídica suficiente para estar
      contratualmente vinculado.
    </p>
    <p>
      Este contrato encontra-se permanentemente acessível nos avisos legais do
      nosso website, podendo ser descarregado no momento da contratação.
    </p>

    <h2 className={styles.section_title}>
      <strong>1. Preços e impostos</strong>
    </h2>

    <p>
      Em contrapartida pelo produto adquirido, o CLIENTE concorda expressamente
      em pagar ao COVIPNEUS LDA os valores especificados no momento da compra na
      tabela de preços estabelecida no site{' '}
      <u>
        <a href="https://www.rodimotor.pt/">www.rodimotor.pt</a>
      </u>{' '}
      para os produtos escolhidos.
    </p>
    <p>
      Os produtos oferecidos neste website estão sujeitos ao Imposto sobre o
      Valor Acrescentado (IVA) que ESTÁ INCLUÍDO nos preços indicados para cada
      produto.
    </p>
    <p>
      O custo de montagem ou instalação dos produtos NÃO ESTÁ INCLUÍDO no preço.
    </p>
    <p>
      Concluída a seleção de produtos e antes da confirmação da encomenda e
      antes de proceder ao seu pagamento, aparecerá no ecrã o preço final de
      compra, que incluirá o IVA, os custos de instalação e montagem, que se
      refletirão na compra pedido no momento da compra e na fatura.
    </p>
    <p>
      Tudo o que não está especificado na descrição como incluído não está
      incluído.
    </p>

    <h2 className={styles.section_title}>
      <strong>2. Procedimento de compra</strong>
    </h2>
    <p>
      As encomendas só podem ser efetuadas através deste website por indivíduos
      maiores de 14 anos e empresas.
    </p>
    <p>
      No site todos os nossos produtos são detalhados individualmente.&nbsp;O
      procedimento para efetuar e gerir a compra está indicado no site e apenas
      devem ser seguidos os seguintes passos:
    </p>
    <ol className={styles.listNumber}>
      <li>
        <p>Elegir el producto que desee adquirir.</p>
      </li>
      <li>
        Ao selecionar um produto, ele pode ser adicionado ao Carrinho de Compras
        e você pode escolher entre continuar comprando ou processar o pedido.
      </li>
      <li>
        Para processar a encomenda, caso seja a primeira vez que acede ao nosso
        portal, deverá preencher um formulário de registo com os seus dados
        pessoais e criar uma conta pessoal como utilizador.&nbsp;Concluída esta
        etapa, receberá, no e-mail que indicou, a confirmação de que o registo
        foi efetuado corretamente.&nbsp;Se você já é um usuário cadastrado, para
        continuar com o processo de compra, basta digitar o nome de usuário e a
        senha que você gerou na primeira compra.&nbsp;O código de utilizador e a
        palavra-passe são pessoais e correspondem à pessoa que efetuou o
        processo de registo, que é exclusivamente responsável pela sua
        guarda.&nbsp;Através da sua conta pode gerir a informação que temos e
        modificar ou atualizar os seus dados.
      </li>
      <li>
        Em seguida, o pedido será verificado onde os itens selecionados são
        detalhados um a um e o preço total é calculado, incluindo despesas de
        oficina e impostos.
      </li>
      <li>
        Da mesma forma, será necessário selecionar a oficina onde deseja que o
        produto seja instalado e marcar uma data para a montagem.
      </li>
      <li>
        Uma vez processado o processo, será necessário marcar previamente uma
        caixa de verificação para ler e aceitar os termos e condições de venda.
      </li>
      <li>
        O site fornecerá os mecanismos para que o pagamento possa ser feito com
        base no sistema escolhido em cada caso.
      </li>
      <li>
        Uma vez inseridos os dados para pagamento, você deve confirmar o pedido
        pressionando o botão «Comprar».
      </li>
      <li>
        Depois de pressionar o botão «Comprar», você receberá instruções para a
        entrega do produto.
      </li>
    </ol>

    <p>
      Informa-se o CLIENTE que as fotografias e descrições dos produtos no site
      são uma imagem aproximada dos produtos e serviços oferecidos, pelo que é
      possível que possam diferir do original.&nbsp;As descrições e ilustrações
      dos produtos oferecidos através deste site são feitas apenas para fins
      informativos.
    </p>

    <h2 className={styles.section_title}>
      <strong>3. Disponibilidade e Entrega</strong>
    </h2>

    <p>
      Os produtos oferecidos pelo COVIPNEUS LDA estão disponíveis para compra em
      toda a União Européia, mas a entrega e montagem serão realizadas
      exclusivamente em uma das oficinas disponíveis na web.
    </p>

    <p>
      Os pedidos serão processados ​​assim que o pagamento for recebido com
      sucesso.
    </p>

    <p>
      Os produtos oferecidos pelo COVIPNEUS LDA estarão sempre sujeitos à
      disponibilidade, que será indicada especificamente em nosso site para cada
      produto, portanto, se um item oferecido não estiver disponível, o cliente
      será devidamente informado da falta de disponibilidade para
      compra.&nbsp;Em condições normais, todos os produtos que aparecem no nosso
      site estão disponíveis para montagem.
    </p>

    <p>
      Nos casos em que, uma vez efetuada a encomenda, o produto contratado pelo
      CLIENTE se encontre esgotado, este será devidamente informado e será
      oferecido um produto alternativo com características semelhantes ao mesmo
      preço e qualidade do adquirido.&nbsp;Se, ainda assim, o CLIENTE não
      estiver interessado em aceitar a alternativa proposta, o COVIPNEUS LDA
      procederá à devolução do valor já pago e o informará sobre os
      procedimentos e prazos de reembolso.
    </p>

    <p>
      O CLIENTE deverá selecionar uma das datas disponíveis na oficina de sua
      escolha, que, uma vez marcada, será a data de entrega do produto.
    </p>

    <p>
      As entregas e instalações serão feitas durante o horário comercial de
      nossas oficinas.
    </p>

    <p>
      Para ser informado a todo o momento da gestão da sua compra, receberá um
      email a confirmar a sua encomenda e pagamento.
    </p>

    <p>
      Da mesma forma, o CLIENTE pode verificar o estado da sua encomenda através
      do telefone 275779015 ou enviando um email para{' '}
      <u>
        <a href="mailto:apoioaocliente@rodimotor.pt">
          apoioaocliente@rodimotor.pt
        </a>
      </u>{' '}
      durante o horário de funcionamento.
    </p>
    <p>
      Asimismo, el CLIENTE puede consultar el estado de su pedido llamando en
      horario comercial de apertura al teléfono 922 10 94 41 si reside en
      Tenerife, al teléfono 928 58 53 33 si reside en Gran Canaria o enviando un
      correo electrónico a la dirección .
    </p>
    <h2 className={styles.section_title}>
      <strong>4. Pagamento e prazos de pagamento</strong>
    </h2>

    <p>São aceitas as seguintes formas de pagamento:</p>
    <ul>
      <li>
        Cartão de crédito: A transação é feita conectando-se diretamente ao
        banco através de seu gateway de pagamento ou diretamente na oficina.
      </li>
    </ul>

    <p>
      Nos pagamentos pela web, uma vez efetuado o pagamento, você receberá um
      e-mail de confirmação.
    </p>

    <p>
      O COVIPNEUS LDA não tem conhecimento dos dados relativos ao seu cartão de
      crédito.&nbsp;Todos os nossos sistemas de pagamento são totalmente
      seguros.
    </p>
    <h2 className={styles.section_title}>
      <strong>5. Faturas</strong>
    </h2>
    <p>
      Uma vez que o serviço tenha sido realizado e concluído na oficina, você
      receberá a Nota Fiscal da compra.&nbsp;Este será em formato PDF em seu
      espaço pessoal na web ou em papel se você solicitar no workshop.<br></br>O
      CLIENTE é responsável por confirmar o recebimento das notificações e por
      nos informar sobre quaisquer alterações em seus dados, ficando o COVIPNEUS
      LDA exonerado de qualquer responsabilidade decorrente dessa circunstância.
    </p>

    <h2 className={styles.section_title}>
      <strong>6. Garantia</strong>
    </h2>

    <p>
      Todos os nossos produtos estão sujeitos a controlos de qualidade e têm
      garantia contra defeitos de fabrico por um período de 3 anos a partir da
      data de compra.&nbsp;A garantia cobre qualquer defeito de fabricação,
      projeto ou material, embora a falta de conformidade deva ser manifestada
      no prazo de 14 dias após o seu conhecimento.&nbsp;A garantia cobre apenas
      produtos defeituosos.&nbsp;Não cobre uso impróprio ou outros que não sejam
      atribuíveis a defeito do fornecedor ou defeito do produto.&nbsp;Durante o
      período de garantia, o CLIENTE poderá dirigir-se à oficina onde o produto
      foi instalado e procederemos à sua reparação ou substituição.
    </p>
    <p>O documento de garantia é a fatura de compra.</p>

    <h2 className={styles.section_title}>
      <strong>7. Devoluções</strong>
    </h2>

    <p>
      Devoluções ou trocas de produtos somente serão aceitas em caso de defeitos
      de fabricação ou erros de montagem.&nbsp;Em qualquer caso, não devem ter
      decorrido mais de 14 dias desde o recebimento do produto.&nbsp;Será
      verificado antes da aceitação que se trata de defeito ou anomalia de
      fabricação ou embalagem e não de uso, inapropriado ou
      negligente.&nbsp;Estarão sempre acompanhados da respetiva fatura.
    </p>

    <p>
      Tanto no caso de devolução por defeito de fabricação quanto no caso de
      erro no pedido, será oferecido ao CLIENTE, se possível, o reparo da peça
      ou sua substituição por outra alternativa sem custo adicional e sem
      direito, pelo CLIENTE. , a qualquer tipo de compensação.
    </p>

    <p>
      Em qualquer caso, a devolução estará sempre sujeita ao exame prévio por
      parte do COVIPNEUS LDA do estado do produto devolvido e à verificação do
      cumprimento dos requisitos das presentes condições de venda.&nbsp;Uma vez
      recebida a devolução e se for o caso, reembolsaremos o valor cobrado no
      prazo máximo de 30 dias e nos mesmos termos que foram utilizados para
      efetuar o pagamento.
    </p>

    <p>
      O COVIPNEUS LDA reserva-se o direito de rejeitar devoluções comunicadas
      fora do prazo ou produtos que não cumpram os critérios de devolução.
    </p>

    <p>
      As devoluções serão gerenciadas enviando um e-mail para{' '}
      <u>
        <a href="mailto:apoioaocliente@rodimotor.pt">
          apoioaocliente@rodimotor.pt
        </a>
      </u>{' '}
      ou indo até a oficina com a descrição dos motivos e supostas causas da
      devolução.&nbsp;Da mesma forma, será necessário indicar o nome e
      sobrenomes e o número de referência do pedido.&nbsp;Assim que o pedido for
      recebido, nós o processaremos em tempo hábil.
    </p>

    <h2 className={styles.section_title}>
      <strong>8. Direito de Retirada</strong>
    </h2>

    <p>
      Você tem o direito de rescindir este contrato no prazo de 14 dias corridos
      sem necessidade de justificativa.
    </p>

    <p>
      O prazo de retirada expirará, em qualquer caso, no momento da instalação
      do produto na oficina.
    </p>

    <p>
      Para exercer o direito de rescisão, você deve notificar COVIPNEUS LDA Zona
      Industrial do Fundão, Lote 4, Fundão, 6230 483, e-mail:{' '}
      <u>
        <a href="mailto:apoioaocliente@rodimotor.pt">
          apoioaocliente@rodimotor.pt
        </a>
      </u>
      , de sua decisão de rescindir o contrato através de uma declaração
      inequívoca ( por exemplo, uma carta enviada por correio, fax ou
      e-mail).&nbsp;Você pode usar o modelo de formulário de retirada abaixo,
      embora seu uso não seja obrigatório.
    </p>

    <p>Modelo de formulário de saque</p>

    <p>À atenção de</p>

    <p>COVIPNEUS LDA</p>

    <p>Zona Industrial do Fundão</p>

    <p>
      Lote 4, Fundão, 6230 483<br></br>{' '}
      <u>
        <a href="mailto:apoioaocliente@rodimotor.pt">
          apoioaocliente@rodimotor.pt
        </a>
      </u>
    </p>

    <p>
      Venho por este meio informar que rescindi o meu contrato de venda do
      seguinte imóvel:
    </p>

    <p>Recebido/encomendado em:</p>

    <p>Nome do consumidor:</p>

    <p>assinatura do consumidor</p>

    <p>Encontro: Data</p>

    <p>
      Para cumprir o prazo de rescisão, basta que a comunicação relativa ao
      exercício por si deste direito seja enviada antes de expirar o prazo
      correspondente.
    </p>

    <p>
      <strong>Consequências da retirada:</strong>
    </p>

    <p>
      Em caso de rescisão por você, devolveremos todos os pagamentos recebidos
      de você, sem atrasos indevidos e, em qualquer caso, no prazo máximo de 14
      dias corridos a partir da data em que formos informados da sua decisão de
      rescindir este contrato.&nbsp;Procederemos ao referido reembolso
      utilizando o mesmo meio de pagamento utilizado por si para a transação
      inicial, salvo disposição expressa em contrário;&nbsp;em qualquer caso,
      você não incorrerá em nenhuma despesa como resultado do reembolso.
    </p>

    <h2 className={styles.section_title}>
      <strong>
        9. Aperfeiçoamento do contrato e modificações dos serviços
      </strong>
    </h2>

    <p>
      Este contrato será aperfeiçoado com a instalação e pagamento do produto.
    </p>

    <p>
      O COVIPNEUS LDA reserva-se o direito de modificar de qualquer forma as
      características e condições dos seus serviços, sempre com o objetivo de
      melhorá-los e que represente um benefício para o CLIENTE.
    </p>

    <h2 className={styles.section_title}>
      <strong>10. Obrigações das partes</strong>
    </h2>
    <p>
      O COVIPNEUS LDA compromete-se a fornecer ao CLIENTE os produtos e serviços
      contratados, aplicando a máxima diligência na sua disponibilização, bem
      como zelar pela manutenção das instalações necessárias ao funcionamento da
      rede, dispondo de uma adequada equipamentos de informática, e gerenciar as
      operações de montagem da compra realizada através de suas oficinas.
    </p>

    <p>
      O CLIENTE compromete-se a utilizar os serviços que lhe forem
      disponibilizados de boa fé, sem infringir as leis vigentes em qualquer
      matéria ou infringir os direitos de terceiros, bem como pagar pelo produto
      selecionado no prazo e forma estabelecidos no estas condições de venda e
      sua recepção no local indicado para entrega.
    </p>

    <p>
      O COVIPNEUS LDA não garante que a disponibilidade do serviço objeto deste
      contrato seja contínua e ininterrupta, devido a circunstâncias causadas
      por problemas na rede de Internet, falhas nos dispositivos informáticos e
      outras circunstâncias imprevisíveis.&nbsp;De tal forma que o CLIENTE
      concorda em suportar estas circunstâncias dentro de limites razoáveis,
      pelo que renuncia expressamente a reclamar ao COVIPNEUS LDA qualquer
      responsabilidade contratual ou extracontratual por eventuais falhas, erros
      e utilização do serviço contratado.
    </p>

    <h2 className={styles.section_title}>
      <strong>11. Comunicações</strong>
    </h2>

    <p>
      Todas as notificações entre as partes serão feitas preferencialmente por
      e-mail.&nbsp;O CLIENTE é responsável por confirmar o recebimento das
      notificações e por informar ao COVIPNEUS LDA de qualquer alteração em seus
      dados, ficando o COVIPNEUS LDA exonerado de qualquer responsabilidade
      decorrente dessa circunstância.&nbsp;Em caso de modificação, o CLIENTE
      deverá informar o COVIPNEUS LDA no e-mail ou telefones indicados em nosso
      site.
    </p>

    <h2 className={styles.section_title}>
      <strong>12. Compromisso, aceitação e validade do contrato</strong>
    </h2>
    <p>
      O CLIENTE reconhece ter lido e aceitado as condições legais de uso e a
      política de privacidade do site.
    </p>

    <p>
      O CLIENTE reconhece que compreendeu todas as informações relativas aos
      produtos e serviços, incluindo, se for o caso, entrega, oferecidas em
      nosso site, bem como todas as condições e estipulações contidas neste
      contrato eletrônico, para as quais afirma serem suficientes para a
      exclusão do erro no consentimento deste contrato e, portanto, você os
      aceita plena e expressamente.
    </p>

    <p>
      O CLIENTE tem pleno conhecimento de que a aceitação e execução do presente
      contrato se dará mediante o fornecimento dos seus dados e premindo o botão
      «Comprar» indicado no nosso website e que se completará com o pagamento do
      serviço.
    </p>

    <h2 className={styles.section_title}>
      <strong>13. Regulamentos IVA aplicáveis</strong>
    </h2>

    <p>
      Este contrato é de natureza comercial e será regido e interpretado de
      acordo com a lei.
    </p>

    <p>
      No caso de qualquer tipo de discrepância ou divergência entre as partes em
      relação à interpretação, conteúdo ou execução do Contrato que não seja
      resolvida de comum acordo, as Partes submeterão aos tribunais competentes
      em cada caso.
    </p>

    <h2 className={styles.section_title}>
      <strong>14.Dados pessoais </strong>
    </h2>
    <p>
      O processo de contratação exige que preencha formulários de dados pessoais
      para poder processar e gerir as encomendas e, caso o autorize, enviar-lhe
      informação comercial sobre os nossos produtos e serviços.&nbsp;O
      fornecimento de tais dados pessoais requer a aprovação prévia da nossa
      Política de Privacidade.
    </p>

    <p>
      <strong>Atenção:</strong>&nbsp;Estas Condições Gerais de Compra foram
      atualizadas em 10/02/2022.&nbsp;A qualquer momento podemos proceder à sua
      modificação, bem como variar a relação dos preços e produtos
      oferecidos.&nbsp;Por favor, verifique a data de emissão cada vez que você
      se conectar ao nosso site e, assim, certifique-se de que não ocorreram
      alterações que o afetem.
    </p>
  </div>
)

const CondicionesContents = ({ ...props }: Props) => (
  <CondicionesPt {...props} />
)

export default CondicionesContents
